<template>
    <div class="add-new-item">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.itemUpdate')"
            :title="$t('messages.itemUpdate')"
            @submitPressed="submitPressed"
        />
        <items-form
            v-if="item"
            :itemObject="item"
            :action="action"
            @clearAction="action = null"
        />
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import ItemsForm from "./components/ItemsForm.vue";

export default {
    components: {
        TitleBar,
        ItemsForm,
    },
    data() {
        return {
            item: null,
            action: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$Items.getResource({ id }).then((response) => {
                this.item = response.data;
                this.item.itemCategorySelect = {
                    label: this.$helper.getEnumTranslation("ITEM_CATEGORY", this.item.itemCategory, this.$i18n.locale),
                    id: this.item.itemCategory
                }
                this.item.statusSelect = {
                    label: this.$helper.getEnumTranslation("ITEM_STATUS", this.item.status, this.$i18n.locale),
                    id: this.item.status
                }
                this.item.inventureSelect = {
                    label: this.$helper.getEnumTranslation("YES_NO_TYPE", this.item.inventure, this.$i18n.locale),
                    id: this.item.inventure
                }
                if (this.item.category) {
                    this.item.categorySelect = {
                        label: this.item.category.name,
                        id: this.item.category['@id']
                    }
                }
                if (this.item.tool) {
                    this.item.toolSelect = this.$helper.getItemSelectOption(this.item.tool)
                }
                if (this.item.navisionItem) {
                    this.item.navisionItemSelect = this.$helper.getItemSelectOption(this.item.navisionItem)
                }
                this.item.itemVendors = []
                if (this.item.vendors) {
                  this.item.vendors.forEach((itemVendor) => {
                    this.item.itemVendors.push({
                      item: this.item,
                      price: itemVendor.price,
                      leadTime: itemVendor.leadTime,
                      reqQuantity: parseInt(itemVendor.reqQuantity),
                      vendorSelect: {
                        label: itemVendor.vendor.name,
                        id: itemVendor.vendor['@id']
                      }
                    })
                  })
                }
                if (this.item.vendor) {
                    this.item.vendorSelect = {
                        label: this.item.vendor.name,
                        id: this.item.vendor['@id']
                    }
                }
                if (this.item.user) {
                    this.item.userSelect = {
                        label: this.item.user.givenName+' '+this.item.user.familyName,
                        id: this.item.user['@id']
                    }
                }
            });
        },
        submitPressed() {
            this.action = "update";
        },
    },
};
</script>
